.overlay__delimiter {
  opacity: 0.9; }

[class*="delimiter"] figure {
  box-shadow: none; }

.delimiter:before, .delimiter:after {
  content: '';
  width: 100%;
  height: 100%; }

.delimiter__circle .services__wrapper-honeycomb .services__wrapper .icon__cover {
  margin-bottom: 30px; }

.delimiter__circle .services__wrapper-main {
  z-index: 6;
  position: relative; }

.delimiter__circle .overlay__delimiter-before {
  left: 0;
  top: -1px; }
  .delimiter__circle .overlay__delimiter-before svg {
    bottom: auto; }

.delimiter__angle {
  padding-bottom: 13.5vw; }
  @media screen and (max-width: 575px) {
    .delimiter__angle {
      padding-bottom: 60px; } }
  .delimiter__angle .overlay__delimiter-before {
    left: 0;
    bottom: -2px; }
  .delimiter__angle .icon__btn .icon {
    width: 50%; }
  .delimiter__angle .icon__btn .livicon-evo-holder {
    bottom: auto;
    right: auto;
    width: 70% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.delimiter__curve {
  padding-bottom: 5vw; }
  @media (max-width: 992px) {
    .delimiter__curve {
      padding-bottom: 120px !important; } }
  .delimiter__curve .highlight-underline {
    margin-top: 20px; }
  .delimiter__curve .overlay__delimiter-before {
    left: 0;
    bottom: -2px; }

.delimiter__trapezoid .overlay__delimiter-before {
  left: 0;
  bottom: 0;
  top: -1px;
  width: auto; }
  .delimiter__trapezoid .overlay__delimiter-before svg {
    bottom: -1px;
    height: 100%;
    width: 100%; }

.delimiter__corner h1, .delimiter__corner h2 {
  opacity: .1;
  color: #fff; }
  @media screen and (max-width: 575px) {
    .delimiter__corner h1, .delimiter__corner h2 {
      line-height: 25px;
      font-size: 3.4rem; } }

.delimiter__corner .overlay__delimiter-before {
  left: 0;
  bottom: -2px; }

.delimiter__corner * {
  z-index: 5;
  position: relative; }

.delimiter__rounded .overlay__delimiter-after {
  z-index: -1; }

.delimiter__rounded .overlay__delimiter-before {
  left: 0;
  bottom: -1px;
  z-index: -1; }

@media screen and (max-width: 575px) {
  .delimiter__rounded .all-light {
    margin-bottom: 30px; } }

.delimiter__rounded p {
  color: #bce1ff; }

.delimiter__rounded .border-btn {
  border-color: var(--brk-base-2) !important; }

.delimiter__rounded .highlight-underline > .before {
  background: var(--brk-base-2) !important; }

.delimiter__triangle {
  position: relative; }
  .delimiter__triangle-elem-img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }

.overlay__delimiter-before {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  z-index: 5; }
  @media screen and (max-width: 575px) {
    .overlay__delimiter-before {
      display: none; } }
  .overlay__delimiter-before svg {
    position: relative;
    bottom: -6px; }

.overlay__delimiter-after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3; }

@media (min-width: 1700px) {
  .container-xl {
    width: 1700px;
    max-width: 1700px; } }
